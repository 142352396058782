.pf-c-form {
  --pf-global--spacer--sm: 0.2rem !important;
  --pf-global--spacer--form-element: 0.2rem !important;
}

.pf-c-form__label-text {
  font-weight: normal;
}

.pf-c-form__group-label {
  padding-bottom: 0 !important;
}

#root {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-mono {
  font-family: "RedHatMono";
}

.bg-green-100 {
  background-color: rgb(220 252 231);
}
.bg-blue-50 {
  background-color: rgb(239 246 255);
}
.bg-blue-100 {
  background-color: rgb(219 234 254);
}
.bg-red-50 {
  background-color: rgb(254 242 242);
}
.bg-red-100 {
  background-color: rgb(254 226 226);
}
.text-red-500 {
  color: rgb(239 68 68);
}
.text-yellow-500 {
  color: rgb(234 179 8);
}
